import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import JumboPhoto from "../../../components/jumbophoto"
import { Container, Row, Col } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="Bankruptcy Costs | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>How Much Does Bankruptcy Cost?</h1>
				<hr/>

				<p>No two cases are exactly alike, but bankruptcy is more affordable than you think. We base our legal fees on the facts and circumstances of each specific case. We will quote you a specific fee which can be paid in installments after your free initial consultation. Note that all attorneys’ fees and court filing fees for a Chapter 7 case must be paid in full in order to file your case. The filing fees for a Chapter 7 case are $335.00 as of June 1, 2014.</p>

				<p>A Chapter 13 case can be filed upon payment of the court filing fee and a portion of the attorney's fees. The balance of the Chapter 13 legal fees can be paid from the monthly plan payment that you make for your Chapter 13 payment plan. The filing fees for a Chapter 13 are $310.00 as of June 1, 2014.</p>

				<p>Additional fees:</p>

				<ul>
					<li>Credit Reports</li>
					<li>Credit Counseling</li>
					<li>Personal Financial Management</li>
				</ul>

				<p>We do not typically quote Bankruptcy fees over the phone due to the fact that each bankruptcy and client is unique, and we prefer to meet you in person to assess your individual situation.</p>
			</Col>
		</Row>
	</Container>
</Layout>